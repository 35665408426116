import {
	ApolloClient,
	InMemoryCache,
	NormalizedCacheObject,
	HttpLink,
} from '@apollo/client'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
	return new ApolloClient({
		ssrMode: typeof window === 'undefined',
		link: new HttpLink({
			uri: '/api/graphql',
			credentials: 'same-origin',
			// credentials: 'include',
		}),
		cache: new InMemoryCache({
			// addTypename: false,
		}),
	})
}

export function initializeApollo(): ApolloClient<NormalizedCacheObject> {
	// Create the Apollo Client once in the client-side
	if (!apolloClient) {
		apolloClient = createApolloClient()
	}

	// apolloClient.clearStore()

	return apolloClient
}

export function useApollo(): ApolloClient<NormalizedCacheObject> {
	return initializeApollo()
}
