import * as React from 'react';
import * as Types from '../declarations';
import { versionFromHostname } from '../lib/withVersion';

// Define the interface for the context
interface VersionContext {
  version: Types.VERSION;
  setVersion?: (version: Types.VERSION) => void;
}

// Create the context
export const VersionContext = React.createContext<VersionContext>({
  version: process.env.NEXT_PUBLIC_SITE_VERSION as Types.VERSION
});
const VersionProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  // Use the existing function for version detection based on hostname
  const getVersionFromHostname = (): Types.VERSION => versionFromHostname(window.location.hostname);

  // Set the initial state based on the hostname
  const [version, setVersion] = React.useState<Types.VERSION>(process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION ?? getVersionFromHostname());
  return <VersionContext.Provider value={{
    version,
    setVersion
  }} data-sentry-element="unknown" data-sentry-component="VersionProvider" data-sentry-source-file="VersionContext.tsx">
			{children}
		</VersionContext.Provider>;
};

// export { VersionContext, VersionProvider }

export default VersionProvider;