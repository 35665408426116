import * as Types from '../declarations'

export const unitSelector = (state: Types.AppState) => state.unit

export const userAuthStatusIsKnown = (state: Types.AppState): boolean => {
	return state.userAuthStatusIsKnown
}

export const authedUser = (
	state: Types.AppState,
): Types.App.PseudoUser | undefined => {
	return state?.user
}

export const isAuthenticated = (state: Types.AppState): boolean => {
	return !!state?.user
}

export const isUser = (state: Types.AppState): boolean => {
	return state?.user?.role === Types.App.DataTypes.UserRole.USER
}
export const isGuide = (state: Types.AppState): boolean => {
	return state?.user?.role === Types.App.DataTypes.UserRole.GUIDE
}
export const isUserAdmin = (state: Types.AppState): boolean => {
	return (
		state?.user?.role === Types.App.DataTypes.UserRole.ADMIN ||
		state?.user?.role === Types.App.DataTypes.UserRole.SUPER_ADMIN
	)
}

export const isUserSuperAdmin = (state: Types.AppState): boolean => {
	return state?.user?.role === Types.App.DataTypes.UserRole.SUPER_ADMIN
}

export const isGuest = (state: Types.AppState): boolean => {
	return state?.userAuthStatusIsKnown === true && !state?.user
}

export const currentFeedbackEditSection = (
	state: Types.AppState,
): Types.App.DataTypes.FeedbackSection | undefined => {
	return state?.editFeedbackSection
}

export const isSignUpOpen = (state: Types.AppState): boolean => {
	return state.signUpOpen
}

export const isLoginOpen = (state: Types.AppState): boolean => {
	return state.logInOpen
}
