import * as Types from '../declarations'

export enum ActionType {
	LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
	AUTH_STATUS_VERIFIED = 'AUTH_STATUS_VERIFIED',
	LOGOUT = 'LOGOUT',
	CHANGE_UNIT = 'CHANGE_UNIT',
	SUGGEST_EDIT_OPEN = 'SUGGEST_EDIT_OPEN',
	SUGGEST_EDIT_CLOSE = 'SUGGEST_EDIT_CLOSE',
	OPEN_SIGNUP = 'OPEN_SIGNUP',
	OPEN_LOGIN = 'OPEN_LOGIN',
	CLOSE_SIGNUP = 'CLOSE_SIGNUP',
}

export type Action =
	| {
			type: ActionType.AUTH_STATUS_VERIFIED
			isVerified: boolean
			user?: Types.App.PseudoUser
	  }
	| {
			type: ActionType.LOGIN_SUCCEEDED
			token: string
			user: Types.App.PseudoUser
	  }
	| {
			type: ActionType.LOGOUT
	  }
	| {
			type: ActionType.CHANGE_UNIT
			payload: string // todo: enum?
	  }
	| {
			type: ActionType.SUGGEST_EDIT_OPEN
			feedbackSection: Types.App.DataTypes.FeedbackSection
	  }
	| {
			type: ActionType.SUGGEST_EDIT_CLOSE
	  }
	| {
			type: ActionType.OPEN_SIGNUP
	  }
	| {
			type: ActionType.OPEN_LOGIN
	  }
	| {
			type: ActionType.CLOSE_SIGNUP
	  }

export const verifiedAuthStatus = (
	isVerified: boolean,
	user?: Types.App.PseudoUser,
): Action => {
	return {
		type: ActionType.AUTH_STATUS_VERIFIED,
		isVerified,
		user,
	}
}

export const attemptLoginSucceeded = (
	token: string,
	user: Types.App.PseudoUser,
): Action => {
	return {
		type: ActionType.LOGIN_SUCCEEDED,
		token,
		user,
	}
}

export const logout = (): Action => {
	return {
		type: ActionType.LOGOUT,
	}
}

export const openSuggestFeedbackModal = (
	feedbackSection: Types.App.DataTypes.FeedbackSection,
): Action => {
	return {
		type: ActionType.SUGGEST_EDIT_OPEN,
		feedbackSection,
	}
}

export const closeSuggestFeedbackModal = (): Action => {
	return {
		type: ActionType.SUGGEST_EDIT_CLOSE,
	}
}

export const openSignUp = (): Action => {
	return {
		type: ActionType.OPEN_SIGNUP,
	}
}

export const openLogin = (): Action => {
	return {
		type: ActionType.OPEN_LOGIN,
	}
}

export const closeSignUp = (): Action => {
	return {
		type: ActionType.CLOSE_SIGNUP,
	}
}
